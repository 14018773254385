import ImgWithFallback from './ImgWithFallBack';
import light_logo_source from '../assets/logos/prepmed_white.png';
import light_logo_fallback from '../assets/logos/prepmed_white.webp';
import dark_logo_source from '../assets/logos/prepmed_blue.png';
import dark_logo_fallback from '../assets/logos/prepmed_blue.webp';

interface LogoProps {
	useDarkLogo?: boolean;
	classes?: string;
	height?: string;
	handleClick?: () => void;
}

const Logo = ({
	useDarkLogo = false,
	classes = 'ml-4',
	height = 'h-10',
	handleClick
}: LogoProps) => {
	if (handleClick !== undefined) {
		return (
			<div onClick={handleClick}>
				<ImgWithFallback
					altText='prepmed-logo'
					classes={`${classes} ${height} cursor-pointer`}
					src={useDarkLogo ? dark_logo_source : light_logo_source}
					fallback={useDarkLogo ? dark_logo_fallback : light_logo_fallback}
				/>
			</div>
		);
	}

	return (
		<ImgWithFallback
			altText='prepmed-logo'
			classes={`${classes} ${height}`}
			src={useDarkLogo ? dark_logo_source : light_logo_source}
			fallback={useDarkLogo ? dark_logo_fallback : light_logo_fallback}
		/>
	);
};

export default Logo;
