import { useNavigate } from 'react-router-dom';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';
import Card from '../../../../components/ui/Card';
import Headline from '../../../../components/ui/Headline';
import MaterialIcons from '../../../../components/ui/icons/MaterialIcons';
import routes from '../../../../config/routes';
import type { FlashcardRecord } from '../../../../data/interface-flashcard';

interface StudySessionResultsProps {
	results: FlashcardRecord;
}
const StudySessionResults = ({ results }: StudySessionResultsProps) => {
	const navigateTo = useNavigate();
	const { setFlashcardSettings } = useFlashcardContext();
	const handleClick = () => {
		setFlashcardSettings(undefined);
		navigateTo(routes.APP.INDEX);
	};

	const handleNavigate = () => {
		setFlashcardSettings(undefined);
		navigateTo(routes.APP.PATH_TO.TEST_REVIEW, {
			state: {
				results
			}
		});
	};

	return (
		<Card>
			<div className='flex flex-col items-center justify-center space-y-8 bg-white'>
				<Headline title='Resultados' isCentered={true} type='h4' />
				<p>
					Aquí está tu resultado final de está sesión de flashcards. ¡Sigue
					haciéndolas! Las flashcards son una excelente herramienta de estudio
					que ayudan a internalizar conceptos más rápido.
				</p>
				<div className='flex justify-center items-center'>
					<strong style={{ fontSize: 'xx-large' }}>
						{results.correctas.length}
					</strong>
					<span className='mx-2'>/</span>
					<span>{results.length}</span>
				</div>
				<p className='mt-1 mb-0' style={{ marginBottom: '0!important' }}>
					Tuviste {results.correctas.length} correctas de {results.length} en
					total.
				</p>

				<div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 items-center justify-center w-3/4 lg:w-6/12'>
					{/* justify-between */}
					{/* <button
						className='btn bg-brand-450 flex items-center justify-center'
						onClick={handleNavigate}>
						Más Detalles
						<MaterialIcons icon='send' classes='ml-4' />
					</button> */}

					<button
						className='btn-large bg-gray-400 shadow-lg flex items-center justify-center'
						onClick={handleClick}>
						<MaterialIcons icon='dashboard' classes='mr-4' />
						<span>Panel Principal</span>
					</button>
				</div>
			</div>
		</Card>
	);
};
export default StudySessionResults;
