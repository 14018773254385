import { useNavigate } from 'react-router-dom';
import Logo from '../../../../components/Logo';
import Container from '../../../../components/ui/Container';
import { useLoadingContext } from '../../../../context/LoadingContext';
import routes from '../../../../config/routes';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';

// .test-mode {
// position: absolute;
// width: 100%;
// min-height: 100vh;
// height: fit-content;
// }

interface TestModeContainerProps {
	children?: React.ReactNode; // best, accepts everything React can render
}

const TestModeContainer = ({ children }: TestModeContainerProps) => {
	const navigateTo = useNavigate();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { setTestSelections } = useQuestionBankContext();
	const { setFlashcardSettings } = useFlashcardContext();

	const handleGoBack = () => {
		const isConfirmed = window.confirm(
			'Si sales ahora perderás todo tu progreso. ¿Estás segur@ que quieres salir del examen?'
		);
		if (isConfirmed) {
			setLoadingMessage('Espera un momentopor favor...');
			setLoading(true);
			setTestSelections(undefined);
			setFlashcardSettings(undefined);
			setTimeout(() => {
				navigateTo(routes.APP.PATH_TO.DASHBOARD, { replace: true }); // ? TODO: This is not working
			}, 1000);
		}
	};

	return (
		<div className='relative w-full bg-gray-800 h-screen overflow-x-hidden'>
			<div className='absolute top-0 right-0 bottom-0 left-0 bg-test-mode h-fit'>
				<div className='pt-8 pb-20 pl-4'>
					<Logo handleClick={handleGoBack} />
				</div>

				<Container>{children}</Container>
			</div>
		</div>
	);
};
export default TestModeContainer;
