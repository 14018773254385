// TODO: 'AS CONST' => www.youtube.com/watch?v=6M9aZzm-kEc
export default Object.freeze({
	ADMIN_LINKS: {
		INDEX: '/admin',
		HABILITAR_CUENTA: '/admin/habilitar-cuenta',
		REPORTS: '/admin/admin-reports',
		USER_REPORTS: '/app/admin/user-reports'
	},
	AUTHENTICATION: {
		LOGIN: '/acceder',
		NEW_PASSWORD: '/nuevo-password',
		SIGNUP: '/registrate',
		REGISTER: '/registrate'
	},
	CHECKOUT: {
		CHECKOUT: '/checkout',
		PAYPAL: '/checkout/paypal',
		CHECKOUT_SUCCESS_BASE_PATH: '/checkout/success/session_id/:id',
		CHECKOUT_SUCCESS: '/checkout/success/session_id/',
		CHECKOUT_FAIL: '/checkout/failed'
	},
	EMAIL: {
		VERIFICATION: '/verifica-tu-correo',
		VERIFIED: '/correo-verificado/'
	},
	HOME: '/',
	PUBLIC: {
		ABOUT_US: '/sobre-nosotros',
		COLABORADORES: '/colaboradores',
		COMING_SOON: '/pronto',
		CONTACT_US: '/contactanos',
		POLICY_COOKIES: '/cookies',
		PREGUNTAS_FRECUENTES: '/preguntas-frecuentes',
		PREGUNTAS_FRECUENTES_WITH_ID: '/preguntas-frecuentes/:id',
		PRIVACY_POLICY: '/politica-de-privacidad',
		SOCIAL_LINKS: '/links',
		TOS_PAGE: '/terminos-de-servicio'
	},
	APP: {
		PATH_TO: {
			DASHBOARD: '/app/dashboard',
			FLASHCARDS: '/app/flashcards',
			QUESTION_SELECTIONS: '/app/preguntas',
			STATS: '/app/estadisticas',
			RECURSOS: '/app/recursos',
			TEST_MODE: '/app/preguntas/examen',
			TEST_PREV_RESULTS: '/app/preguntas/resultados-anteriores',
			TEST_REVIEW: '/app/preguntas/resultados',
			MEMBERSHIP: {
				PLANS: '/app/membresias',
				// SUBSCRIPTIONS: '/app/membresias/suscripciones'
				CHECKOUT: '/app/checkout',
				CHECKOUT_PAYPAL: 'checkout/paypal'
			}
		},
		INDEX: '/app',
		DASHBOARD: 'dashboard',
		ENURM: {
			GRADE_CONSULTATION: '/app/consulta-de-notas'
		},
		FLASHCARDS: {
			CREATE: '/app/flashcards/crea',
			MANAGER: '/app/flashcards/manejar',
			DASHBOARD: 'flashcards',
			FLASHCARD_DETAILS_ID: '/app/flashcard/details',
			FLASHCARD_DETAILS: '/app/flashcard/details/:id',
			STUDY: '/app/flashcards/study-mode',
			REVIEW: '/app/flashcards/review-mode'
		},
		QUESTIONS: {
			ADMIN: 'preguntas/admin',
			CREATE: 'preguntas/create',
			DASHBOARD: 'preguntas',
			EDIT_MODE: 'preguntas/edit-mode',
			PREGUNTAS_VISTAS: 'preguntas/vistas',
			QUESTION_DETAILS: 'preguntas/details/:id',
			TEST_MODE: 'preguntas/examen',
			TEST_PREV_RESULTS: 'preguntas/resultados-anteriores',
			TEST_REVIEW: 'preguntas/resultados'
		},
		RECURSOS: {
			DASHBOARD: 'recursos',
			ENURM: 'recursos/enurm',
			CMF: 'recursos/maxilofacial',
			PREPDERM: 'recursos/dermatologia'
		},
		STATS: 'estadisticas',
		USER_PROFILE: '/app/tu-perfil'
	},
	PRODUCT: {
		ENURM: '/enurm',
		CMF: '/maxilofacial',
		PREPDERM: '/dermatologia'
	}
});
