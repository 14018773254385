import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useEffect, useState } from 'react';
import type { SyntheticEvent } from 'react';
import MainTag from '../../../layout/Main';
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';
import { useLoadingContext } from '../../../context/LoadingContext';
import Logo from '../../../components/Logo';
import useUserContext from '../../../api/UserContext';
import Headline from '../../../components/ui/Headline';
import debugThis, { redirectTo } from '../../../utils/helpers';

import routes from '../../../config/routes';
import Card from '../../../components/ui/Card';
import MaterialIcons from '../../../components/ui/icons/MaterialIcons';
import { Link } from 'react-router-dom';
// SETUP GUIDE: https://dev.to/paypaldeveloper/how-to-add-paypal-checkout-payments-to-your-react-app-53aa#part-2-adding-paypal-to-your-react-app

const CheckoutWithPayPal = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { currentUser, product, updateToPaidViaPayPal } = useUserContext();

	// TODO:: Remove all the 'any' types
	const [{ options, isPending, isResolved }, dispatch] =
		usePayPalScriptReducer();
	const [error, setError] = useState<string>();
	const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

	useEffect(() => {
		setLoadingMessage('Un momento por favor...');
		setLoading(true);
		if (isResolved) {
			setLoading(false);
		}
	}, [isResolved]);
	// const [currency, setCurrency] = useState(options.currency);

	// const onCurrencyChange = (e: SyntheticEvent) => {
	// const value = (e.target as HTMLSelectElement).value;
	// setCurrency(value);
	// dispatch({
	// type: 'resetOptions',
	// value: {
	// ...options,
	// currency: value
	// }
	// });
	// };

	const onCreateOrder = (data: any, actions: any) => {
		if (product === null) return null;
		return actions.order.create({
			purchase_units: [
				{
					reference_id: product.id,
					// description: product.description,
					amount: {
						// currency_code: 'USD',
						value: product.price
					},
					custom_id: product.officialExamName,
					soft_descriptor: 'PREPMEDRD'
				}
			],
			application_context: { shipping_preference: 'NO_SHIPPING' }
		});
	};

	const onApproveOrder = (data: any, actions: any) => {
		setLoadingMessage('Un momento por favor en lo que procesamos tu orden...');
		setLoading(true);
		return actions.order.capture().then((details: any) => {
			// console.log('details', details);
			// debugThis();
			// const name = details.payer.name.given_name as string;
			// alert(`Transaction completed by ${name}`);
			updateToPaidViaPayPal()
				.then(() => {
					// if (currentUser === null || currentUser.uid === undefined) {
					// return null;
					// }
					// const userID = currentUser.uid;
					// const url =
					// `${window.location.origin}${routes.CHECKOUT.CHECKOUT_SUCCESS}${userID}` +
					// '?pymt_source=stripe';
					// redirectTo(url);
					setLoading(false);
					setIsPaymentSuccess(true);
				})
				.catch((err) => {
					setLoading(false);
					console.error('Error updating user to paid via paypal: ', err);
				});
		});
	};

	const onError = (err: any) => {
		console.error('Error:', err);
		setError(err);
	};

	if (product === null) return null;
	if (isPaymentSuccess) {
		return (
			<MainTag>
				<div className='flex flex-col items-center mb-4 mt-10  mx-auto'>
					<div className='container'>
						<Card classes='px-8 py-20 border rounded-md shadow-xl'>
							<Logo useDarkLogo={true} />
							<Headline
								classes='text-success-600'
								title='¡Gracias por tu compra!'
								type='h2'>
								<MaterialIcons icon='check_circle' classes='text-4xl mr-2' />
							</Headline>
							<div className='mt-8 fsize-medium'>
								<p>
									Nos place informarte que hemos recibido tu pago
									satisfactoriamente. Tu tarjeta presentará un cargo a nombre de{' '}
									<span>&quot;PREPMED&quot;</span> o{' '}
									<span>&quot;PREPMEDRD.COM&quot;</span>.
								</p>
								<p>
									Queremos aprovechar la oportunidad para darte una cálida
									bienvenida y desearte la mejor de las suertes al momento de
									tomar el examen. ¡Gracias por confiar en nosotros!
								</p>
								<p>
									Has tomado una excelente decisión y en breve podrás empezar a
									utilizar nuestra plataforma para prepararte a tomar el examen
									que decidirá tu futuro como médico en la República Dominicana.
								</p>
								<p>Presiona el botón debajo para continuar.</p>
							</div>

							<div className='flex justify-end items-end mt-4'>
								<Link
									to={routes.APP.INDEX}
									className='btn bg-brand-450 flex justify-end items-end'>
									Panel Principal
									<i className='material-icons ml-2'>send</i>
								</Link>
							</div>
						</Card>
					</div>
				</div>
			</MainTag>
		);
	}
	return (
		<MainTag>
			<div className='flex flex-col items-center mb-4 mt-10  mx-auto'>
				<div className='container'>
					<Logo useDarkLogo={true} height='h-20' classes='mx-0 mb-8' />
					<div className='mt-8'>
						<div className=' py-2'>
							<Headline type='h4' title='Completa tu pago con PayPal' />
							<p>
								Accede a todas las herramientas de estudio que ofrecemos para tu
								preparación del {product.officialExamName}: simulaciones,
								flashcards, preguntas corregidas y validadas con explicaciones,
								estadísticas, entre otras cosas...
							</p>

							<p>Monto total a pagar: US${product.price}</p>
						</div>
					</div>
					<div className='checkout'>
						{isPending ? (
							<p>LOADING...</p>
						) : (
							// <select value={currency} onChange={onCurrencyChange}>
							// <option value='USD'>💵 USD</option>
							// <option value='EUR'>💶 Euro</option>
							// </select>
							<PayPalButtons
								style={{
									layout: 'vertical',
									color: 'blue',
									shape: 'rect',
									label: 'paypal'
								}}
								createOrder={(data, actions) => onCreateOrder(data, actions)}
								onApprove={(data, actions) => onApproveOrder(data, actions)}
								onError={(err) => onError(err)}
							/>
						)}
					</div>
				</div>
			</div>
		</MainTag>
	);
};
export default CheckoutWithPayPal;
