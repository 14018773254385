import { useEffect, useRef, useState } from 'react';
import GuideMessage from './GuideMessage';
import ErrorMessage from './ErrorMessage';

interface TextInputTypes {
	guideMessage?: string;
	label: string;
	id: string;
	infoMessage?: string;
	parentClass?: string;
	maxLength?: number;
	minLength?: number;
	pattern?: string;
	placeholder?: string;
	isRequired?: boolean;
	handleChange: (value: string) => void;
	handleValidity?: (value: boolean) => void;
}

const TextInput = ({
	guideMessage = '',
	id,
	infoMessage,
	label,
	maxLength,
	minLength,
	parentClass = 'flex justify-between items-center w-full mb-4',
	pattern,
	placeholder,
	isRequired: required = false,
	handleChange,
	handleValidity
}: TextInputTypes) => {
	const textInputRef = useRef<HTMLInputElement>(null);
	const [isOnFocus, setIsOnFocus] = useState<boolean>(true);
	// const [hideInfoMessage, setHideInfoMessage] = useState<boolean>(true);
	const [isValid, setIsValid] = useState<boolean | null>(null);

	const validateText = (): boolean => {
		const valid = textInputRef.current?.validity?.valid;
		if (typeof valid === 'boolean') {
			return valid;
		} else {
			return false;
		}
	};

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>): void => {
		handleChange(e.target.value);
		const validation = validateText();
		if (handleValidity !== null) {
			handleValidity?.(validation);
			setIsValid(validation);
		}
	};

	const ringStyles = 'focus:ring focus:ring-opacity-50';
	const placholderStyles =
		'placeholder:italic placeholder:text-gray-400 placeholder:font-light placeholder:text-sm';

	return (
		<>
			<input
				autoComplete='off'
				type='text'
				id={id}
				// className={`mt-1 py-2 px-3 block w-full rounded-md border border-gray-300 leading-tight shadow-sm text-gray-600 ${placholderStyles} ${ringStyles}  ${
				// isValid !== null
				// ? isValid
				// ? 'focus:ring-success-200 focus:border-success-400'
				// : 'focus:ring-red-400 focus:border-red-200'
				// : 'focus:ring-brand-200 focus:border-brand-200'
				// }`}
				className={`mt-1 py-2 px-3 block w-full rounded-md border border-gray-300 leading-tight shadow-sm text-gray-600 ${placholderStyles} ${ringStyles}  ${
					isValid === true
						? 'focus:ring-success-200 focus:border-success-400'
						: ''
				} ${
					isValid === false
						? 'border-danger-300 focus:ring-red-400 focus:border-red-200 '
						: ''
				} ${
					isValid === null ? 'focus:ring-brand-200 focus:border-brand-200' : ''
				}`}
				maxLength={maxLength !== undefined ? maxLength : undefined}
				minLength={minLength !== undefined ? minLength : undefined}
				placeholder={placeholder !== null ? placeholder : label}
				pattern={pattern !== null ? pattern : undefined}
				ref={textInputRef}
				required={required}
				onBlur={() => setIsOnFocus(false)}
				onChange={(e) => handleAction(e)}
				onFocus={() => setIsOnFocus(true)}
			/>
			<ErrorMessage
				classes='mt-2'
				error={guideMessage}
				showError={isValid === false}
			/>
			{isOnFocus && (
				<>
					{/* {infoMessage !== null && (
						<small
							className='text-red-400'
							hidden={hideInfoMessage || textInputRef.current?.validity?.valid}>
							{infoMessage}
						</small>
					)} */}
					{guideMessage !== '' && <GuideMessage message={guideMessage} />}
				</>
			)}
		</>
	);
};

export default TextInput;
