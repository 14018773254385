import { type SyntheticEvent, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type {
	Flashcard,
	FlashcardData
} from '../../../../data/interface-flashcard';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';
import { useLoadingContext } from '../../../../context/LoadingContext';
import MainSection from '../../../../layout/app/dashboard/MainSection';
import DescriptionTextArea from '../../../../components/forms/DescriptionTextAreaQuill';
import UploadFlashcardImage from './UploadFlashcardImage';
import { scrollToTop } from '../../../../utils/helpers';
import Headline from '../../../../components/ui/Headline';
import InputCheckbox from '../../../../components/forms/InputCheckbox';
import type { Category } from '../../../../data/interface-question';
import { buildFlashcardObject } from '../../../../api/xBuildObjectUtils';

const AddEditFlashcard = () => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { categories, getCategories } = useQuestionBankContext();
	const { createFlashcard, updateFlashcard } = useFlashcardContext();
	const location = useLocation();
	const [card, setCard] = useState<Flashcard>();
	const [front, setFront] = useState<string>();
	const [back, setBack] = useState<string>();
	const [image, setImage] = useState<string>();
	const [category, setCategory] = useState<string[]>([]);
	const [isEdit, setIsEdit] = useState(false);
	const [isDoneEditing, setIsDoneEditing] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	useEffect(() => {
		scrollToTop();
		categories !== undefined &&
			categories.length <= 0 &&
			getCategories()
				.then(() => {
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
				});

		if (location.state !== undefined && location.state !== null) {
			setIsEdit(true);
			setCard(location.state);
			setFront(location.state.front);
			setBack(location.state.back);
			setCategory(location.state.categories);
			if (location.state.image !== undefined) {
				setImage(location.state.image);
			}
		}
		return () => {
			resetForm();
		};
	}, []);

	useEffect(() => {
		setIsDoneEditing(false);
	}, [front, back, category]);

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		setLoadingMessage('Creando flashcard...');
		setLoading(true);
		if (isEdit) {
			handleUpdate()
				.then(() => {
					setLoadingMessage('Flashcard added succesfully.');
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			handleCreate()
				.then(() => {
					setLoadingMessage('Flashcard added succesfully.');
				})
				.catch((error) => {
					console.error(error);
				});
		}
		setTimeout(() => {
			resetForm();
			scrollToTop();
			setLoading(false);
		}, 1500);
	};

	const handleCreate = async () => {
		const newCard: FlashcardData = {
			front: front ?? '',
			back: back ?? '',
			imageFileName: image ?? null,
			categories: category
		};
		await createFlashcard(newCard);
	};

	const handleUpdate = async () => {
		if (card === undefined) return null;
		const newCard = buildFlashcardObject(card.id, {
			...card,
			front,
			back,
			imageFileName: image ?? null,
			categories: category
		});
		if (newCard === null) return null;
		await updateFlashcard(newCard);
		setIsEdit(false);
	};

	const resetForm = () => {
		setFront(undefined);
		setBack(undefined);
		setCategory([]);
		setImage(undefined);
		setIsFormSubmitted(false);
	};

	const handleCategory = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;
		const id = target.id;
		const categoryArray: string[] = category.length > 0 ? [...category] : [];

		if (category.includes(id)) {
			const index = categoryArray.indexOf(id);
			categoryArray.splice(index, 1);
			setCategory([...categoryArray]);
		} else {
			categoryArray.push(id);
			setCategory([...categoryArray]);
		}
	};

	// TODO: Delete image on edit
	return (
		<MainSection title='Crea Tus Flashcards'>
			<div className='flex justify-end'>
				{/* <Link to={FLASHCARDS_LIST}>Devuelta al Dashboard</Link> */}
			</div>

			<form onSubmit={handleSubmit}>
				<DescriptionTextArea
					label='Parte visible del flashcard - "Pregunta"'
					id='front'
					infoMessage='Escribe la pregunta que deseas que aparezca en el flashcard.'
					parentClass='flex justify-between items-center w-full mb-2'
					placeholder='Escribe la pregunta aquí...'
					required={true}
					handleChange={(value: string) =>
						setFront(value.replace(/(<p><br><\/p>)/g, ''))
					}
					value={front}
					isFormSubmitted={isFormSubmitted}
					// value={front}
					// action={(content) =>
					// setFront(content.replace(/(<p><br><\/p>)/g, ''))
					// }
					// tabIndex='1'
				/>
				<DescriptionTextArea
					label='Parte scondida del flashcard - "Respuesta"'
					id='back'
					infoMessage='Escribe la respuesta que deseas que aparezca en el flashcard.'
					parentClass='flex justify-between items-center w-full mb-2'
					placeholder='Escribe la rspuesta aquí...'
					required={true}
					handleChange={(value: string) =>
						setBack(value.replace(/(<p><br><\/p>)/g, ''))
					}
					value={back}
					isFormSubmitted={isFormSubmitted}
					// value={front}
					// action={(content) =>
					// setFront(content.replace(/(<p><br><\/p>)/g, ''))
					// }
					// tabIndex='1'
				/>
				<UploadFlashcardImage
					onUpload={(imgPath: string) => setImage(imgPath)}
				/>
				{isEdit && card !== undefined && card.imageFileName !== null && (
					<button
						onClick={() => setImage(undefined)}
						className='bg-danger-100 text-danger-500 hover:bg-danger-200 hover:text-danger-600'>
						Borrar imagen
					</button>
				)}

				{/* CATEGORY SELECTIONS */}
				<div className='my-2'>
					<Headline title='Categorías' type='h6' />
					{categories?.map((c: Category) => {
						return (
							<InputCheckbox
								key={c.id}
								id={c.id}
								label={c.name}
								isChecked={category.includes(c.id)}
								parentClass='relative inline-flex items-start my-1 2xl:w-full w-1/2'
								handleChange={handleCategory}
							/>
						);
					})}
				</div>

				{/* SUBMIT BUTTON */}
				<div className='flex justify-end mt-4'>
					<button
						type='submit'
						className='flex items-center bg-brand-450 hover:bg-brand-600'
						disabled={
							front === undefined || back === undefined || category.length === 0
						}
						onClick={(e) => {
							setLoading(true);
							handleSubmit(e);
						}}>
						{isEdit ? 'Actualizar Flashcard' : 'Crear Flashcard'}
						<i className='material-icons ml-4'>send</i>
					</button>
				</div>
			</form>
		</MainSection>
	);
};
export default AddEditFlashcard;
