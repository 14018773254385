interface headlineProps {
	classes?: string;
	title: string;
	type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	isCentered?: boolean;
	children?: React.ReactNode;
}

const Headline = ({
	classes = '',
	children,
	isCentered = false,
	title,
	type
}: headlineProps) => {
	const finalClasses = isCentered
		? `flex items-center justify-center mb-2 ${classes}`
		: `flex items-center mb-2 ${classes}`;
	if (type === 'h1') {
		return (
			<h1 className={finalClasses}>
				{children}
				{title}
			</h1>
		);
	}
	if (type === 'h2') {
		return (
			<h2 className={finalClasses}>
				{children}
				{title}
			</h2>
		);
	}
	if (type === 'h3') {
		return (
			<h3 className={finalClasses}>
				{children}
				{title}
			</h3>
		);
	}
	if (type === 'h4') {
		return (
			<h4 className={finalClasses}>
				{children}
				{title}
			</h4>
		);
	}
	if (type === 'h5') {
		return (
			<h5 className={finalClasses}>
				{children}
				{title}
			</h5>
		);
	}
	if (type === 'h6') {
		return (
			<h6 className={finalClasses}>
				{children}
				{title}
			</h6>
		);
	}
	return (
		<div className={finalClasses}>
			{children}
			{title}
		</div>
	);
};
export default Headline;
