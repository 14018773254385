interface MainProps {
	children: React.ReactNode;
	backgroundColor?: string;
}
const MainTag = ({ backgroundColor = 'bg-brand-50', children }: MainProps) => {
	return (
		<main
			className={`flex flex-1 flex-col justify-center items-center ${backgroundColor}`}>
			{/* max-w-md */}
			<div className='w-full flex flex-col justify-center'>{children}</div>
		</main>
	);
};
export default MainTag;
