import parseHTML from 'html-react-parser';

interface FuenteProps {
	fuentes?: string;
}
const Fuente = ({ fuentes }: FuenteProps) => {
	if (fuentes === undefined) return null;
	return (
		<blockquote
			className='border-l-4 border-l-brand-450 pl-4 pt-2 md:pt-0 md:pb-2 mb-4 md:mb-0 md:ml-4'
			style={{ minHeight: '45px' }}>
			<strong>Fuente:</strong>
			<div>{parseHTML(fuentes)}</div>
		</blockquote>
	);
};
export default Fuente;
