import { useEffect, useMemo, useState } from 'react';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';
import TestModeContainer from '../../examenes/test-mode/TestModeContainer';
import { motion, AnimatePresence } from 'framer-motion';
import TestProgress from '../../examenes/test-mode/TestProgress';

import { shuffle } from 'lodash';
import CardContent from './CardContent';
import useHideElementOnMount from '../../../../hooks/useHideElementOnMount';
import { useLoadingContext } from '../../../../context/LoadingContext';
import StudySessionResults from './StudySessionResults';
import type { FlashcardRecord } from '../../../../data/interface-flashcard';
import Swal from 'sweetalert2';

const StudyMode = () => {
	useHideElementOnMount('#defaul-copyright');
	// #region CONTEXT IMPORTS
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { flashcardSettings, setFlashcardSettings, saveFlashcardStudyResults } =
		useFlashcardContext();
	const flashcards = useMemo(
		() =>
			shuffle(
				flashcardSettings?.flashcards?.slice(0, flashcardSettings.length)
			),
		[flashcardSettings?.flashcards, flashcardSettings?.length]
	);
	// #endregion

	// const [theseFlashcards, setTheseFlashcards] = useState();
	const [currentCard, setCurrentCard] = useState(0);
	const [correctas, setCorrectas] = useState<string[]>([]);

	const [animation, setAnimation] = useState('none');

	const [showProgress, setShowProgress] = useState(true);
	const [showCard, setShowCard] = useState(true);
	const [showBack, setShowBack] = useState(false);

	const [showResults, setShowResults] = useState(false);
	const [finalResults, setFinalResults] = useState<any>();

	useEffect(() => {
		// if (flashcardSettings === undefined) return;
		// setLoadingMessage('Cargando tus flashcards...');
		// setLoading(true);
		// setTimeout(() => {
		// setLoading(false);
		// }, 3000);

		return () => {
			if (currentCard !== 0) {
				setFlashcardSettings(undefined);
				setCorrectas([]);
				setCurrentCard(0);
			}
		};
	}, []);

	if (flashcardSettings === undefined) return null;
	const variants = {
		cardBack: {},
		rotate: {
			rotateY: 90,
			transition: { duration: 0.25 }
			// transitionEnd: {
			// rotateY: 0,
			// },
		},
		rotateBack: { rotateY: 0, transition: { duration: 0.25 } },
		// You can do whatever you want here, if you just want it to stop completely use `rotate: 0`
		// stop: { y: [0, -10, 0], transition: { repeat: Infinity, repeatDelay: 3 } },
		easeIn: {
			x: -1000,
			transition: { delay: 0.05, duration: 0.2 },
			transitionEnd: {
				zIndex: 1,
				x: 0,
				opacity: 1
			},
			name: 'easeIn'
		},
		center: { zIndex: 1, x: 0, opacity: 1, name: 'center' },
		easeOut: {
			zIndex: 0,
			x: 1000,
			opacity: 0,
			scale: 0,
			transition: { delay: 0, duration: 0.5 },
			transitionEnd: {
				x: -1000,
				opacity: 1,
				zIndex: 1
			},
			name: 'easeOut'
		},
		none: {}
	};

	const handleAnimationComplete = (anm: any) => {
		if (anm.name === 'easeOut') {
			setShowProgress(false);
			if (currentCard <= flashcardSettings.length) {
				setShowCard(true);
			}
		}
		if (anm.name === 'center') setShowProgress(true);
	};

	const addOne = () => {
		const thisCard = flashcards[currentCard].id;
		// console.info(thisCard);
		setCorrectas((prev) => [...prev, thisCard]);
		if (currentCard + 1 < flashcardSettings.length) {
			slideIntoNext();
		} else {
			setCurrentCard(currentCard + 1);
			Swal.fire({
				title: '¡Terminaste!',
				text: '¿Estás listo para ver tus resultados?',
				icon: 'success',
				allowEscapeKey: false,
				allowOutsideClick: false,
				confirmButtonText: 'Ver Resultados',
				confirmButtonColor: '#4CAF50',
				showCancelButton: false
			})
				.then((result) => {
					if (result.isConfirmed) {
						handleFinalFlashcard();
					} else {
						setShowResults(false);
						setShowCard(true);
						setCurrentCard(0);
						setCorrectas([]);
					}
				})
				.catch((error) => {
					console.error('Error saving test record', error);
				});
		}
	};

	const slideIntoNext = () => {
		setShowCard(false);
		setShowBack(false);

		if (currentCard + 1 !== flashcardSettings.length) {
			setCurrentCard(currentCard + 1);
		}
	};

	const handleFinalFlashcard = () => {
		setLoadingMessage(
			'Tu sesión de estudio ha terminado. Estamos analizando tus resultados.'
		);
		setLoading(true);
		storeFinalResults()
			.then((record) => {
				if (record === true) {
					setShowResults(true);
				} else {
					// todo: handle this error better
				}
				setTimeout(() => {
					setLoading(false);
				}, 3000);
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error saving test record', error);
				// TODO: Let the user know there was an error saving the test record and support was notified. There's nothing for them to do but the issue will be fixed.
			});
	};

	const storeFinalResults = async () => {
		const cardIDs: string[] = [];
		flashcards.forEach((c) => {
			cardIDs.push(c.id);
		});
		const thisSession: FlashcardRecord = {
			banco: flashcardSettings.banco,
			length: flashcardSettings.length,
			mode: flashcardSettings.mode,
			topics: flashcardSettings.topics,
			correctas,
			selectedCards: cardIDs
		};
		setFinalResults(thisSession);
		const result = await saveFlashcardStudyResults(thisSession);
		return result;
	};

	if (showResults && finalResults !== undefined) {
		return (
			<TestModeContainer>
				<StudySessionResults results={finalResults} />
			</TestModeContainer>
		);
	}

	return (
		<TestModeContainer>
			<div className='sm:mt-8 flex flex-col justify-center items-center overflow-hidden'>
				{/* FLASHCARD */}
				{currentCard < flashcardSettings.length ? (
					<>
						{showCard && showProgress && (
							<div
								className='text-right'
								style={{
									width: window.innerWidth < 767 ? '350px' : '400px'
								}}>
								<TestProgress
									current={currentCard}
									total={flashcardSettings.length}
								/>
							</div>
						)}

						<AnimatePresence mode='wait'>
							{showCard && (
								<motion.div
									className='h-full overflow-hidden'
									initial={variants.easeIn}
									animate={variants.center}
									exit={variants.easeOut}
									transition={{ duration: 0.85 }}
									onAnimationComplete={(a) => {
										handleAnimationComplete(a);
									}}>
									<motion.div
										className='bg-white flex justify-center items-center rounded-md p-8 mb-8'
										variants={variants}
										animate={animation}
										style={{
											boxShadow: '0px 0px 15px 10px rgba(0,0,0,0.25)',
											WebkitBoxShadow: '0px 0px 15px 10px rgba(0,0,0,0.25)',
											MozBoxShadow: '0px 0px 15px 10px rgba(0,0,0,0.25)',
											minHeight: '250px',
											width: window.innerWidth < 767 ? '350px' : '400px'
										}}
										onAnimationComplete={(a) => {
											if (a === 'rotate') {
												setShowBack(!showBack);
												setAnimation('rotateBack');
											}
										}}>
										<motion.button
											className='no-focus'
											style={{
												position: 'absolute',
												top: '1rem',
												right: '1rem'
											}}
											onClick={() => {
												setAnimation('rotate');
											}}
											whileHover={{ scale: 1.5 }}>
											{/* <i className='material-icons'>360</i> */}
											<svg
												xmlns='http://www.w3.org/2000/svg'
												height='24px'
												viewBox='0 -960 960 960'
												width='24px'
												fill='currentColor'>
												<path d='m360-160-56-56 70-72q-128-17-211-70T80-480q0-83 115.5-141.5T480-680q169 0 284.5 58.5T880-480q0 62-66.5 111T640-296v-82q77-20 118.5-49.5T800-480q0-32-85.5-76T480-600q-149 0-234.5 44T160-480q0 24 51 57.5T356-372l-52-52 56-56 160 160-160 160Z' />
											</svg>
										</motion.button>

										<CardContent
											flashcard={flashcards[currentCard]}
											showBack={showBack}
										/>
									</motion.div>

									{/* //~BUTTONS */}
									<div className='flex w-full justify-end'>
										{!showBack ? (
											<button
												className='btn bg-brand-450 no-focus flex'
												onClick={() => {
													setAnimation('rotate');
												}}>
												{/* <i className='material-icons mr-2'>360</i> */}
												<svg
													xmlns='http://www.w3.org/2000/svg'
													height='24px'
													viewBox='0 -960 960 960'
													width='24px'
													fill='currentColor'>
													<path d='m360-160-56-56 70-72q-128-17-211-70T80-480q0-83 115.5-141.5T480-680q169 0 284.5 58.5T880-480q0 62-66.5 111T640-296v-82q77-20 118.5-49.5T800-480q0-32-85.5-76T480-600q-149 0-234.5 44T160-480q0 24 51 57.5T356-372l-52-52 56-56 160 160-160 160Z' />
												</svg>
												Ver Reverso
											</button>
										) : (
											<>
												<button
													className='btn bg-brand-450 mr-2'
													onClick={addOne}>
													Correcta
												</button>
												<button
													className='btn bg-gray-400'
													onClick={slideIntoNext}>
													Incorrecta
												</button>
											</>
										)}
									</div>
								</motion.div>
							)}
						</AnimatePresence>
					</>
				) : null}
			</div>
		</TestModeContainer>
	);
};
export default StudyMode;
