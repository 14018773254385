import routes from '../../../config/routes';
import Headline from '../../../components/ui/Headline';
import TrustBadges from './TrustBadges';
import type { ProductData } from '../../../data/interface-question-bank';
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import { useLoadingContext } from '../../../context/LoadingContext';

interface PaymentButtonsProps {
	product: ProductData;
	handleStripeCheckout: () => void;
}

const PaymentButtons = ({
	handleStripeCheckout,
	product
}: PaymentButtonsProps) => {
	const navigateTo = useNavigate();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const handlePaypalCheckout = () => {
		setLoadingMessage('Un momento por favor...');
		setLoading(true);
		navigateTo(routes.CHECKOUT.PAYPAL);
	};

	// TODO: Need to get price from the product in firebase
	return (
		<>
			<Headline
				classes='my-8'
				isCentered={true}
				title='Elige tu método de pago para continuar'
				type='h4'
			/>
			<div className='flex flex-col items-center justify-center my-8'>
				<div className='flex justify-between w-full'>
					<div>
						<button
							className='btn bg-brand-450'
							onClick={handleStripeCheckout}
							// disabled={PRODUCT.quantity < 1}
						>
							{/* <i className='fab fa-cc-stripe right'></i> */}
							Pago con Stripe
						</button>
						<p className='mt-4 text-sm'>
							*No acepta tarjetas del <strong>Banco Popular</strong>{' '}
						</p>
					</div>
					<div>
						<div className='flex justify-end'>
							<button
								// to={routes.CHECKOUT.PAYPAL}
								// state={{ price: paypalPrice }}
								className='btn bg-brand-450'
								onClick={handlePaypalCheckout}>
								Pago con PayPal
								{/* <i
								className='fab fa-cc-paypal '
								style={{ verticalAlign: 'top' }}></i> */}
							</button>
						</div>
						<p className='mt-4 text-sm'>*Acepta todas las tarjetas</p>
					</div>
				</div>
				<TrustBadges />
			</div>
		</>
	);
};
export default PaymentButtons;
