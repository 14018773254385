import Headline from '../../../components/ui/Headline';

interface MainSectionProps {
	children?: React.ReactNode; // best, accepts everything React can render
	bgColor?: string;
	title: string;
	subTitle?: string;
	btnTitle?: string;
	btnAction?: () => void;
}

const MainSection = ({
	btnTitle,
	btnAction,
	bgColor = 'bg-white',
	subTitle,
	title,
	children
}: MainSectionProps) => {
	// TODO: Move to UI folder
	return (
		<main className={`py-4 md:py-20 lg:pl-72 ${bgColor} min-h-screen`}>
			<div className='px-10 lg:px-4'>
				<div className='mx-auto w-full xl:w-11/12 2xl:w-10/12 '>
					{title !== '' && <Headline title={title} type='h1' />}
					{subTitle !== undefined && <p> {subTitle}</p>}
					{children}
				</div>
			</div>
		</main>
	);
};
export default MainSection;
