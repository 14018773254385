import ImgWithFallback from '../../../../components/ImgWithFallBack';
import type { SignupStepsIDs } from '../../../../data/interface-signup-steps';
import Button from '../../../../components/ui/Button';

// IMAGES
import enurm from '../../../../assets/images/products/enurm.png';
import maxilofacial from '../../../../assets/images/products/maxilofacial.jpg';
import prepderm from '../../../../assets/images/products/prepderm.png';
import { useLoadingContext } from '../../../../context/LoadingContext';
import useUserContext from '../../../../api/UserContext';
import type {
	Product,
	QuestionBankID
} from '../../../../data/interface-question-bank';
import Card from '../../../../components/ui/Card';
import Headline from '../../../../components/ui/Headline';
import Swal from 'sweetalert2';
interface ChooseYourTestProps {
	nextStep: (step: SignupStepsIDs) => void;
}
const ChooseYourTest = ({ nextStep }: ChooseYourTestProps) => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { updateExamTarget } = useUserContext();

	const handleSelection = (id: QuestionBankID) => {
		const product = products.find((p) => p.id === id);
		const productName: string = product?.name ?? '***ERROR***';

		Swal.fire({
			title: 'Confirma tu examen de interés',
			html: `Seleccionaste el examen <b>${productName}</b>. Si tu elección es la correcta, haz clic en 'Si, seleccionar' para continuar.`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Sí, seleccionar',
			cancelButtonText: 'Cancelar',
			confirmButtonColor: '#5bc31e',
			cancelButtonColor: '#FF3C38'
		})
			.then((result) => {
				if (result.isConfirmed) {
					setLoadingMessage('Actualizando tu perfil...');
					setLoading(true);
					updateExamTarget(id)
						.then((res) => {
							setLoadingMessage('');
							setLoading(false);
							if (res === 'exam-target-updated') {
								nextStep('informacion-adicional');
							}
						})
						.catch((err) => {
							setLoadingMessage('');
							console.error(err);
							setLoading(false);
						});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const products: Product[] = [
		{
			id: 'enurm',
			name: 'ENURM',
			description:
				'Enfocado 100% a la preparacón del Examen Nacional Único de Aspirantes a Residencias Medicas (ENURM). Incluye las preguntas de ENURMs anteriores (desde el 2000 en adelante) y otras proprietarias de PrepMed.',
			imageSrc: enurm,
			imageAlt: 'PrepMed ENURM logo',
			href: '#',
			isAvailable: true
		},
		{
			id: 'maxilofacial',
			name: 'Cirugía Buco-Maxilofacial',
			description:
				'Enfocado 100% al ENURODONT, Examen de Admision de la Residencia de Cirugía Buco-Maxilofacial. Incluye las preguntas de examenes anteriores y otras proprietarias de PrepMed.',
			imageSrc: maxilofacial,
			imageAlt: 'PrepMed Cirugía Buco-Maxilofacial logo',
			href: '#',
			isAvailable: true
		},
		{
			id: 'dermatología',
			name: 'Dermatología',
			description:
				'Enfocado 100% en el Examen Extraordinario de Dermatología. Incluye las preguntas de examenes anteriores y otras proprietarias de PrepMed.',
			imageSrc: prepderm,
			imageAlt: 'PrepDerm logo',
			href: '#',
			isAvailable: false
		}
	];

	return (
		<Card>
			<Headline
				classes='text-brand-700 mb-8'
				isCentered={true}
				title='Elige uno de Nuestros Productos'
				type='h6'
			/>
			<section aria-labelledby='collections-heading' className='bg-gray-100'>
				<div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
					<div className='mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-32'>
						<div className='mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0'>
							{products.map((product) => (
								<div key={product.name}>
									<div
										className='group relative cursor-pointer'
										onClick={() => handleSelection(product.id)}>
										<div className='relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 sm:h-64'>
											<ImgWithFallback
												altText={product.imageAlt}
												classes='h-full w-full object-cover object-center'
												src={product.imageSrc}
												fallback={product.imageSrc}
											/>
										</div>
										<h3 className='mt-6 text-sm text-gray-500'>
											<p>
												<span className='absolute inset-0' />
												{product.name}
											</p>
										</h3>
										<p
											className='text-base font-semibold text-gray-900'
											style={{ height: '120px' }}>
											{product.description}
										</p>
										<Button
											title='Seleccionar'
											bgColor='bg-gray-300'
											hoverBgColor='bg-brand-450 group-hover:bg-brand-600'
											handleClick={() => handleSelection(product.id)}
											classes='mt-4'
											isDisabled={!product.isAvailable}
										/>
										{!product.isAvailable && (
											<p className='text-sm text-gray-400'>
												Aún no está disponible.
											</p>
										)}
									</div>
								</div>
							))}
						</div>

						{/* <div className='flex justify-end mt-10'>
							<Button
								title='Continuar'
								icon='send'
								iconPosition='right'
								bgColor='bg-brand-450'
								hoverBgColor='hover:bg-brand-550'
								handleClick={() => nextStep('informacion-adicional')}
								classes='mt-4'
							/>
						</div> */}
					</div>
				</div>
			</section>
		</Card>
	);
};
export default ChooseYourTest;
