const StickyScrollToTop = () => {
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (
		<div style={{ position: 'sticky', top: '92vh', zIndex: '1' }}>
			<button
				onClick={scrollToTop}
				title='Edit Question'
				className='btn bg-brand-450 rounded-full'
				style={{ position: 'absolute', right: '-5vw' }}>
				<i className='material-icons text-sm font-semibold'>north</i>
			</button>
		</div>
	);
};
export default StickyScrollToTop;
