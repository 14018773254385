import { useEffect, useState } from 'react';
import type { SyntheticEvent } from 'react';
import Headline from '../../../../components/ui/Headline';
import SelectElement from '../../../../components/forms/SelectElement';
import NumberInput from '../../../../components/forms/NumberInput';
import {
	bank as bankOptions,
	modo,
	quantities
} from '../../../../data/option-objects/flashcardsOptions';
import type { Category } from '../../../../data/interface-question';
import FormLabel from '../../../../components/forms/FormLabel';
import InputCheckbox from '../../../../components/forms/InputCheckbox';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import useUserContext from '../../../../api/UserContext';
import type {
	Flashcard,
	FlashcardCreator,
	FlashcardMode
} from '../../../../data/interface-flashcard';
import ToggleModal from '../../../../components/modals/ToggleModal';
import { shuffle } from 'lodash';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../config/routes';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';
import { useLoadingContext } from '../../../../context/LoadingContext';

const SelectFlashcardsForm = () => {
	const navigateTo = useNavigate();
	// #region CONTEXT IMPORTS
	const { currentUser } = useUserContext();
	const { categories, getCategories } = useQuestionBankContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { flashcardSettings, setFlashcardSettings, flashcards, getFlashcards } =
		useFlashcardContext();
	// #endregion

	// #region USE STATE
	const [showModal, setShowModal] = useState<boolean>(false);
	const [bank, setBank] = useState<FlashcardCreator>('todos');
	const [length, setLength] = useState<string>();
	const [mode, setMode] = useState<FlashcardMode>('aleatorio');
	const [topics, setTopics] = useState<string[]>([]);
	const [showOtherQty, setShowOtherQty] = useState(false);
	// #endregion

	useEffect(() => {
		if (flashcards === undefined || categories === undefined) {
			setLoadingMessage('Cargando flashcards...');
			setLoading(true);
			loadRequiredData()
				.then(() => {
					setTimeout(() => {
						setLoading(false);
					}, 2000);
				})
				.catch((error) => {
					setLoading(false);
					console.error(error);
				});
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		flashcardSettings !== undefined && setTestReady();

		// return () => {
		// setFlashcardSettings(undefined);
		// }
	}, [flashcardSettings]);

	if (currentUser === null) return null;

	const loadRequiredData = async () => {
		await getCategories();
		await getFlashcards();
	};
	const handleCategoryToggle = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;
		const topicClicked = target.id;
		if (topics.includes(topicClicked)) {
			let arr = [];
			arr = topics.filter((t) => {
				return t !== topicClicked;
			});
			setTopics(arr);
		}
		setTopics([...topics, topicClicked]);
	};

	const getTotalQuestionsByCategories = (categoryID: string) => {
		if (flashcards === undefined || flashcards.length === 0) return 0;
		const count = flashcards.filter((q) => {
			return q.categories.includes(categoryID);
		}).length;
		return ` (${count})`;
	};

	const handleSubmit = (e: SyntheticEvent) => {
		e.preventDefault();
		setShowModal(true);
	};

	const handleLengthChange = (value: string) => {
		if (value === 'otra-cantidad') {
			setShowOtherQty(true);
		} else {
			setLength(value);
			setShowOtherQty(false);
		}
	};

	const setTestReady = () => {
		navigateTo(routes.APP.FLASHCARDS.STUDY);
	};

	const handleStartTest = () => {
		if (flashcards === undefined) return;
		// TODO: NEED TO VALIDATE MINIMUM REQUIREMENTS FOR THE FORM TO BE SUBMITTED
		const filteredCards = flashcards.filter((card: Flashcard) => {
			if (mode === 'por-categoria') {
				return topics.includes(card.categories[0]);
			}
			return true;
		});
		const shuffledQuestions = shuffle([...flashcards]);
		const limitedFlashcards = shuffledQuestions.slice(0, Number(length));
		setFlashcardSettings({
			banco: bank,
			length: Number(length),
			mode,
			topics,
			flashcards: limitedFlashcards
		});
	};

	if (categories === undefined) return null;
	return (
		<div className='w-full lg:w-6/12 xl:w-4/12'>
			<Headline
				title='opciones de flashcards'
				type='h2'
				classes='text-base uppercase font-semibold mt-0 mb-4'
			/>
			<form onSubmit={handleSubmit}>
				{/* Elige una cantidad */}
				<>
					<SelectElement
						defaultOption='- Elige una cantidad -'
						id='test-type'
						icon='ballot'
						iconFam='material-icons'
						label='Elige una cantidad'
						options={quantities}
						value={length}
						handleChange={(e) => handleLengthChange(e)}
					/>
					{showOtherQty && (
						<NumberInput
							guideMessage='100 es el máximo de preguntas que puedes elegir.'
							id='testLengthInput'
							icon='dialpad'
							iconFam='material-icons'
							min={1}
							max={100}
							label='¿Qué cantidad quieres?'
							placeholder='Elige del 1 a 100 '
							handleChange={(v) => setLength(v.toString())}
						/>
					)}
				</>

				{/* Elige un banco de flashcards */}
				<SelectElement
					defaultOption='- Elige una opción -'
					id='flashcard-bank'
					icon='label'
					iconFam='material-icons'
					label='Elige un banco de Flashcards'
					options={bankOptions}
					value={bank}
					handleChange={(v) => setBank(v as FlashcardCreator)}
				/>

				{/* Elige un modo */}
				<SelectElement
					defaultOption='- Elige una opción -'
					id='flashcard-bank'
					icon='label'
					iconFam='material-icons'
					label='Elige un modo de Flashcards'
					options={modo}
					value={mode}
					handleChange={(v) => setMode(v as FlashcardMode)}
				/>

				{mode === 'por-categoria' && (
					<>
						<FormLabel label='Elige las categorías de interés' />
						<div className='w-full'>
							{categories?.length > 0 &&
								categories
									.filter(
										(category: Category) =>
											category.id !== 'M8rTWnWd2Wh9WLZtsMQn'
									) // Evaluacion diagnostica 1
									.filter(
										(category: Category) =>
											category.id !== 'DpVZVQbCpngLsSuCXX8J'
									) // Evaluacion diagnostica 2
									.sort((a: Category, b: Category) => {
										if (a.name < b.name) {
											return -1;
										}
										if (a.name > b.name) {
											return 1;
										}
										return 0;
									})
									.map((category: Category) => {
										return (
											<InputCheckbox
												key={category.id}
												id={category.id}
												label={category.name}
												labelAdditionlInfo={getTotalQuestionsByCategories(
													category.id
												).toString()}
												isChecked={topics.includes(category.id)}
												parentClass='relative inline-flex items-start my-1 2xl:w-full w-1/2'
												handleChange={(e) => handleCategoryToggle(e)}
											/>
										);
									})}
						</div>
					</>
				)}

				{flashcards !== undefined && flashcards?.length >= Number(length) && (
					<ToggleModal
						actionBtnText='Empezar'
						handleAction={handleStartTest}
						buttonClass='bg-brand-450'
						buttonText='Empezar'
						headline='Confirma...'
						isOpen={showModal}
						isDisabled={
							Number(length) <= 0 ||
							flashcards?.length < Number(length) ||
							(mode === 'por-categoria' && topics.length === 0)
						}
						handleVisibility={() => setShowModal(false)}>
						<div className='flex justify-center'>
							<p className='fsize-medium'>¿Estás list@ para estudiar?</p>
						</div>
					</ToggleModal>
				)}
			</form>
		</div>
	);
};
export default SelectFlashcardsForm;
