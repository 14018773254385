import Logo from '../../components/Logo';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import type { NavLink, Navigation } from '../../data/interface-navigation';
import Productos from './Productos';
import routes from '../../config/routes';
import { useAuthContext } from '../../api/AuthContext';
import { Link } from 'react-router-dom';

interface MobileNavProps {
	navigation: Navigation;
	isOpen: boolean;
	isPrivateRoute: boolean;
	toggleMenu: (b: boolean) => void;
}

const MobileNav = ({
	navigation,
	isOpen,
	isPrivateRoute,
	toggleMenu
}: MobileNavProps) => {
	const { signOutCurrentUser } = useAuthContext();
	return (
		<Dialog as='div' className='lg:hidden' open={isOpen} onClose={toggleMenu}>
			<div className='fixed inset-0 z-50' />
			<Dialog.Panel className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
				<div className='flex items-center justify-between'>
					<Link to={routes.HOME} className='-m-1.5 p-1.5'>
						<span className='sr-only'>PrepMedRD</span>
						<Logo useDarkLogo={true} classes='h-8 w-auto' />
					</Link>
					<button
						type='button'
						className='-m-2.5 rounded-md p-2.5 text-gray-700'
						onClick={() => toggleMenu(false)}>
						<span className='sr-only'>Cerrar menu</span>
						<XMarkIcon className='h-6 w-6' aria-hidden='true' />
					</button>
				</div>
				<div className='mt-6 flow-root'>
					<div className='-my-6 divide-y divide-gray-500/10'>
						<div className='space-y-2 py-6'>
							{!isPrivateRoute &&
								navigation.map((item: NavLink) =>
									item.name === 'Programas' ? (
										<Productos key={item.name} />
									) : (
										<a
											key={item.name}
											href={item.href}
											className='-mx-3 block rounded-lg px-7 py-2 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
											{item.name}
										</a>
									)
								)}
						</div>
						<div className='py-6'>
							{isPrivateRoute ? (
								<a
									href={routes.HOME}
									onClick={() => signOutCurrentUser()}
									className='-mx-3 block rounded-lg px-6 py-2.5 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
									Cerrar Sesión
								</a>
							) : (
								<a
									href={routes.AUTHENTICATION.LOGIN}
									className='-mx-3 block rounded-lg px-6 py-2.5 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
									Acceder
								</a>
							)}
						</div>
					</div>
				</div>
			</Dialog.Panel>
		</Dialog>
	);
};
export default MobileNav;
