import Headline from '../../../../components/ui/Headline';
import MaterialIcons from '../../../../components/ui/icons/MaterialIcons';
import type { Record } from '../../../../data/interface-question';
import Question from './Question';
import Answer from './Answer';
import Explanation from './Explanation';

interface TestReviewQuestionsProps {
	records: Record[];
}

const TestReviewQuestions = ({ records }: TestReviewQuestionsProps) => {
	let count = 1;

	return (
		<>
			{records.map(
				({
					id,
					question,
					answer,
					explanation,
					selectedAnswer,
					isSelectedAnswerCorrect,
					fuente,
					audioFileName,
					imageFileName,
					showImageInQuestion
				}) => {
					return (
						<div
							key={id}
							className={`bg-white border ${
								isSelectedAnswerCorrect ? 'border-green-50' : 'border-red-50'
							} shadow sm:rounded-xl mb-8`}>
							<div className='px-4 py-5 sm:p-6'>
								<div className='mt-2 sm:flex sm:items-start sm:justify-between'>
									<div className='max-w-xl text-sm text-gray-500'>
										<Headline
											classes='font-light uppercase text-sm leading-6 text-gray-400'
											title={`Pregunta ${count++}`}
										/>
									</div>
									{/* // TODO: Add report question button, modal and modal functionality */}
									{/* <div className='mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-start '>
										<button
											type='button'
											className='inline-flex items-center rounded-md bg-red-100 p-1 text-xs text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500'>
											<MaterialIcons icon='report' />
										</button>
									</div> */}
								</div>
								<Question
									question={question}
									imagePathName={imageFileName}
									showImageInQuestion={showImageInQuestion}
								/>
								<Answer
									isSelectedAnswerCorrect={isSelectedAnswerCorrect}
									correctAnswer={answer}
									selectedAnswer={selectedAnswer}
								/>
								<hr className='w-3/4 mx-auto my-8' />
								<Explanation
									id={id}
									explanation={explanation}
									fuentes={fuente}
									audioFileName={audioFileName}
									imagePathName={imageFileName}
									showImageInQuestion={showImageInQuestion}
								/>
							</div>
						</div>
					);
				}
			)}
		</>
	);
};
export default TestReviewQuestions;
