import parseHTML from 'html-react-parser';
import type { Question as IQuestion } from '../../../../data/interface-question';
import Card from '../../../../components/ui/Card';
import { useEffect, useState } from 'react';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import { useLoadingContext } from '../../../../context/LoadingContext';

interface QuestionPropTypes {
	question: IQuestion;
}

// TODO: Add a report an issue modal
const Question = ({ question }: QuestionPropTypes) => {
	const { getQuestionImageURL } = useQuestionBankContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [imageURL, setImageURL] = useState<string | undefined>();

	const checkForImg = async () => {
		if (question.imageFileName !== undefined) {
			setLoadingMessage('');
			setLoading(true);
			if (question.showImageInQuestion === true) {
				setLoadingMessage('Cargando imagen...');
				const url = await getQuestionImageURL(question.imageFileName);
				setImageURL(url);
			} else {
				setLoading(false);
			}
			return false;
		} else {
			return false;
		}
	};

	useEffect(() => {
		checkForImg()
			.then(() => {
				// console.log('Image URL:', imageURL);
			})
			.catch((err) => console.error(err));
		return () => {
			setImageURL(undefined);
		};
	}, [question]);

	useEffect(() => {
		setLoading(false);
	}, [imageURL]);
	return (
		<Card classes='bg-white shadow-md rounded-md  p-8 mb-8 '>
			<div>
				{/* <ReportAnIssueModal update={update} questionID={question.id} /> */}
				{parseHTML(question.question)}
			</div>

			{/* // TODO: Enhancement - Make this img width: 50% and make if clickable so it expands to full screen */}
			{imageURL !== undefined && (
				<div className='flex justify-center mt-2'>
					<img className='responsive-img' src={imageURL} alt={imageURL} />
				</div>
			)}
		</Card>
	);
};
export default Question;
