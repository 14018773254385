// RESOURCE: https://firebase.google.com/docs/web/setup
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
	addDoc,
	collection,
	doc,
	getFirestore,
	setDoc
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';

// console.info('ENVIRONMENT:', process.env);
export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMEANT_ID
};

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

/**
 * Logs events to Analytics, takes 2 params: event, object (optional)
 */
// export const logEvent = (event, object = {}) => {
// firebase.analytics().logEvent(event, object);
// };

// Initialize Firebase with a "default" Firebase project
export const prepmedApp = initializeApp(firebaseConfig);

// Option 1: Access Firebase services via the defaultProject variable
// let cloudStorage = getStorage(prepmed);
// let firestore = getFirestore(prepmed);

// Option 2: Access Firebase services using shorthand notation
export const auth = getAuth(prepmedApp);
export const db = getFirestore(prepmedApp);
// export const cloudStorage = getStorage();
const analytics = getAnalytics(prepmedApp);

// ======================================== LOG EVENTS ========================================
/**
 * Logs events to Google Analytics, takes 2 params: event, object (optional)
 */
export const handleEventLog = (event: string, object: unknown = null) => {
	const uid: string = auth.currentUser?.uid ?? 'No user logged in';
	const issue = object !== null ? object : null;
	const error = uid !== null ? { ...issue, uid } : { ...issue };
	logEvent(analytics, event, error);

	if (
		!event.includes('AUTHENTICATION_ERROR') &&
		!event.includes('LOGIN_ERROR:')
	) {
		const errorsRef = collection(db, 'errors');
		try {
			addDoc(errorsRef, {
				createTS: new Date(),
				event: event,
				...error
			})
				.then()
				.catch((err) => {
					console.error('Error logging event:', err);
				});
		} catch (e) {
			console.error('Error logging event:', e);
		}
	}
};
